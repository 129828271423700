.in {
 top:10px;
}

.modalz{
    position: absolute;
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 2;
    width: 285px;
    height: 95%;
    margin: auto;
}

.modal_in{
    display: flex;
    background-color: white;
    position: relative;
    z-index: 2.5;
    height: 80%;
    margin: auto;
    top: 60px;
    border: solid 5px black;
    opacity: 1;
    border-radius: 1rem;
    justify-content: center;
    flex-direction: column;
     overflow-y: auto;
     font-size: 10px;
     width: 100%;
}
.agreementform{
    font-size: 13px;
    color: black;
    opacity: 150;

}
.top_a{
    position: relative;
    padding: 20px;
    font-size: 20px;
}

.total{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    color:black;
}
/* .totalCate{
    border:solid black 5px;
    position:relative;
    width:30%
}
.totalCate_name{
    border-bottom:solid black 5px; 
    margin: auto;
    padding: 15px 15px 15px 15px;
    background-color:#07381B;
    color:white;
    font-size: 19px;
}
.totalCate_price{
    margin:auto;
    padding: 20px 0;
    font-size: 13px;
}
 */

/* @media (max-width:480px){
    .add{
    }
    .total{
        font-size: 14px;
        font-weight: 500;
    }
    .totalCate{
        border:solid black 2px;
    }
    .totalCate_name{
        border-bottom:solid black 2px; 
    }
    .totalCate_price{
        margin:auto;
        padding: 20px 0;
        font-size: 13px;
    }
}     */

.MuiTableCell-root .MuiTableCell-body {
    padding: 2px;

}
.MuiTableCell-paddingNone {
    padding: 0;
}


/* waves */
.waves-effect{position:relative;cursor:pointer;display:inline-block;overflow:hidden;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-tap-highlight-color:transparent;vertical-align:middle;z-index:1;/*will-change:opacity, transform;*/}
.waves-effect .waves-ripple{position:absolute;border-radius:50%;width:20px;height:20px;margin-top:-10px;margin-left:-10px;opacity:0;background:rgba(0, 0, 0, .1);transition:all 0.7s ease-out;-webkit-transition-property:-webkit-transform, opacity;-moz-transition-property:-moz-transform, opacity;-o-transition-property:-o-transform, opacity;transition-property:transform, opacity;-webkit-transform:scale(0);-moz-transform:scale(0);-ms-transform:scale(0);-o-transform:scale(0);transform:scale(0);pointer-events:none}
.waves-notransition{-webkit-transition:none !important;-moz-transition:none !important;-o-transition:none !important;-ms-transition:none !important;transition:none !important}
.waves-circle{-webkit-transform:translateZ(0);-moz-transform:translateZ(0);-ms-transform:translateZ(0);-o-transform:translateZ(0);transform:translateZ(0);-webkit-mask-image:-webkit-radial-gradient(circle, white 100%, black 100%)}
.waves-input-wrapper{border-radius:0.2em;vertical-align:bottom}
.waves-input-wrapper .waves-button-input{position:relative;top:0;left:0;z-index:1}
.waves-circle{text-align:center;width:2.5em;height:2.5em;line-height:2.5em;border-radius:50%;-webkit-mask-image:none}
.waves-block{display:block}
