.bottom{
    display: flex;
    flex-direction: row;
}
.bottom_bot{
    display: flex;
    flex-direction: column;
}
.add{
    display: flex;
    position: relative;
    justify-content: center;
    width:100%;
    display:flex;
    margin-bottom: 20px;
    background-image: image("videhome_logo.png");
    flex-direction: column;
}
.modal{
    position: relative;
    width: 100%;
    justify-content: center;

}
.modal div{
    width: 100%;
    position: relative;
    justify-content: center;
}
.total{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    color:black;
    fontFamily: "Pretendard-Light",
}
.totalCate{
   box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
   border-radius: 1rem;
    position:relative;
    width:30%
}
.totalCate_name{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: auto;
    padding: 15px 15px 15px 15px;
    background-color:#1D7955;
    color:white;
}
.totalCate_price{
    margin:auto;
    padding: 20px 0;
}

/* 
@media (max-width:480px){
    .add{
    }
    .total{
        font-size: 14px;
        font-weight: 500;
    }
    .totalCate{
        border:solid black 2px;
    }
    .totalCate_name{
        border-bottom:solid black 2px; 
    }
    .totalCate_price{

    }
}     */
