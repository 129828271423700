.rate{
    margin: 5px;
    left: 0;
    bottom: 0;
    justify-content: center;
    margin: 0 40px 0 40px;
    font-size: 10.5px;
}
.ER_wrapper{
    display: flex;
    position: relative;
    flex-direction: row;
    color: black;
    justify-content: center;
    width: 90%;
    padding:10px 10px 0 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 1rem;
    margin: auto;
}
.ER_wrapper .col1{
    margin: auto;
    text-align: center;
    justify-content: space-around;
    vertical-align: center;
    width: 50%;
}
.ER_wrapper .col2{
    margin: auto;
    text-align: center;
    justify-content: space-around;
   align-items: center;
   width: 50%;
  ;
}

.ER_wrapper .col1a{
    margin: auto;
    text-align: center;
    justify-content: space-around;
    vertical-align: center;
    width: 33.33%;
  
}
.ER_wrapper .col2a{
    margin: auto;
    text-align: center;
    justify-content: space-around;
   align-items: center;
   width: 33.33%;
  ;
}
.ER_wrapper .col3a{
    margin: auto;
    text-align: center;
    justify-content: space-around;

   align-items: center;
   width: 33.33%;
}
.row{
    font-size: 15px;
    color: black;
    width: 100%;
    height: 60px;
   position: relative;
   align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

