.App {
  text-align: center;
  
}

.Apps {
  position: relative;
  flex-direction: column;
  justify-content: space-evenl;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  align-items: center;

  margin-top: 20px;
  margin-bottom: 30px;

}
.Apps p{
  color: #07381B;
  font-weight: 600;
  font-size: 30px;
}
.left{
  position: flex;
  float: left;
  width: 22.5%;
  min-width:100%;
  height: 93%;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.background{
  position: absolute;
  width: 20%;
  top: 50%;
  left: 40%;
  opacity: 0.6;
  z-index: -1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.middles{
  position: flex;
  float: left;
  height: 93%;
  overflow-y: scroll;
 
  min-width:100%;

}


.right{
  position: flex;
  float: left;
  width: 22.5%;
  min-width:100%;
  min-height: 350px;
  flex-direction: column;

}
.wrapper{
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;

}
.header_top img{
  position: relative;
  width: 100%;
  border-bottom: solid 2px ;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.img-top-nav{
  position: relative;
  left: -10px;
  width: 110px;
  
}
.img-logo{
  width: 40%;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;

  z-index: 2;
}
.img-logo-login{
  width: 30%;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
  padding: 1px;
  z-index: 2;
}
.img-topnav-2{
  width: 20%;
  padding:0px;
  margin-top: 100px;
}
.img-logo-side{
  width: 75%;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
  padding: 20px 0px;
  z-index: 2;
}
.img-top-icon{
  width: 35px;
}

.App .Body{
  display: flex;
  justify-content: center;
  align-items: center;
  color:  #ccc;
  font-weight: inherit;
  font-size: 20px;
  animation-name: example;
  animation-duration: 4s;
  overflow: hidden;
 width: 100%;
 height: 100%;
}
.App .Body .Header_center {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.App .Body .Header_center .child {
  flex: 1 0 41%; /* explanation below */
  margin: 5px;
  height: auto;
  overflow: hidden;
}
.App .Body .Header_center:hover .img-logo {
  opacity: 0.3;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.App .Body .Header_center:hover .middle {
  opacity: 1;
}


/* fading option */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: 0} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: 0} 
  to {opacity: 1}
}

.topnav {
  background-color: #FBAA13;
  overflow: hidden;
border-bottom: solid 1px F5F5F5;
  width: 100%;
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 3;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  text-align: center;
  width: 15%;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  opacity: 0.9;
  transition: transform .3s; 
  z-index: 3;
}

/* Change the color of links on hover */
.topnav a:hover {
  transform: scale(1.3);
  z-index: 3;
  opacity: 1;
  
}


/* Add a color to the active/current link */
.topnav .pressed {
  transform: scale(1.3);
  z-index: 3;
}

.right-text{ 
  position: absolute;
  right: 0px;

}

.login {
  border: solid;
  color: 	#07381B;
  margin-bottom: 20px;
  width: 300px;
}
.create_ac {
  border: solid;
  color: 	#07381B;
}

.middle a {
  color: 	#07381B;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  opacity: 0.9;
  transition: transform .3s; 
}
.middle a :hover {
  color:white;
  background-color: 	#07381B;
  opacity: 1;
  width: 100%;
}
.Footer{

  font-size: 13px;
  flex-direction: row;
  font-family: Pretendard-Light

}


.Footer div{
  margin: 5px;
  left: 0;
  bottom: 0;
  justify-content: center;
  font-family: Pretendard-Light
}
.Footer .top{
  font-family: Pretendard-Light;
}
.Footer .bottom{
  font-family: Pretendard-Light;
}

.spinner-div{
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  border-left: 16px solid pink;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* MOBLIE */
@media (max-width:480px){

  .App {
    text-align: center;
  }
  
  .Apps {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .Apps p{
    color: #07381B;
    font-weight: 600;
    font-size: 30px;
  }
  .left{
    position: flex;
    width: 100%;
    margin: 0px;

  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  .background{
    position: absolute;
    width: 20%;
    top: 50%;
    left: 40%;
    opacity: 0.6;
    z-index: -1;
  }
  
  .middles{
    position: flex;
    float: left;
    width: 100%;
    margin: 0px;
  }
  
  .right{
    position: flex;
    float: left;
    width: 100%;
    flex-direction: column;
    margin: 0px;
    min-width: 100%;
  }
  .wrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
  }
  .header_top img{
    width: 100%;
    position: fixed;
    top: 60px;
    z-index: 3;

  }

  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .img-top-nav{
    position: relative;
    width: 110px;
  }
  .img-logo{
    width: 50%;
    transition: 2s ease;
    backface-visibility: hidden;
    opacity: 1;

  }
  .img-top-icon{
    width: 35px;
  }
  
  .App .Body{
    display: flex;
    justify-content: center;
    align-items: center;
    color:  #ccc;
    font-weight: inherit;
    font-size: 20px;
    animation-name: example;
    animation-duration: 4s;
    animation-delay: 0s;
    overflow: hidden;
   width: 100%;
    position: relative;
   height: 100%;
   min-height: 630px;
   padding-top: 32px;
   padding-bottom: 50px;

  }
  .App .Body .Header_center {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    bottom: 0px;
  }
  .App .Body .Header_center:hover .img-logo {
    opacity: 0.3;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .App .Body .Header_center:hover .middle {
    opacity: 1;
  }
  
  
  /* fading option */
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @-webkit-keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  .topnav {
    background-color: white;
    overflow: hidden;
    width: auto;
    display: flex;
    align-items: center;
    height: 46px;
    position: fixed;
border-bottom: 1px solid 	#D3D3D3;
    z-index: 3;
    top: 0;
  }
  
  /* Style the links inside the navigation bar */
  .topnav a {
    float: left;

    text-align: center;
    padding: 0 10px;
    text-decoration: none;
    font-size: 10px;
    cursor: pointer;
    opacity: 0.9;
    transition: transform .3s; 
    width: 35%;
    transform: scale(1.4);
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    color: 	#07381B;
    opacity: 1;
    
  }
  
  
  /* Add a color to the active/current link */
  .topnav .pressed {
    transform: scale(1.3);
  }
  
  .right-text{ 
    position: absolute;
    right: 0px;
  
  }
  
  .login {
    border: solid;
    border-radius: 1rem;
    color: 	#07381B;
    margin-bottom: 20px;
    width: 200px;
    margin: auto;
  
    justify-content: center;
    align-items: center;
  }
  .create_ac {
    border: solid;
    color: 	#07381B;
  }
  
  .middle a {
    color: 	#07381B;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    opacity: 0.9;
    transition: transform .3s; 
  }
  .middle a :hover {
    color:white;
    background-color: 	#07381B;
    opacity: 1;
    width: 100%;
  }
  .Footer{
    display: flex;
    font-size: 25px;
    flex-direction: column;
    justify-content: flex-end;
    font-family: Pretendard-Light

    /* border-top: solid 1px black; */
  }
  .Footer div{
 
    left: 0;
    bottom: 0;
    justify-content: center;
    font-size: 12px;
    font-family: Pretendard-Light;
    text-align:left;
    color:	#808080
  }
  .Footer .top{
    font-family: Pretendard-Light;
  }
  .Footer .bottom{
    font-family: Pretendard-Light;
  }
  
  .fab_button{
    color:"black";
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    right: 20px;
    position: absolute;
    }
    .wrap{
      width: 100%; min-height: 200px;position:relative;
    }
    .wrap.sticky .fab_button{
      position: fixed;
    }
    
}

.weight_img{
  width: 30%;
  top: 50%;
  left: 40%;
}

.buttons{
  font-family: 'Cafe24Oneprettynight'
}                

.map_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  z-index: 1;
}






/* FONTS */
@font-face {
  font-family: 'ONE-Mobile-POP';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-POP.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/EliceDigitalBaeum_Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'EliceDigitalBaeum_Bold2';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/EliceDigitalBaeum_Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


/* effects */

.waves-effect{position:relative;cursor:pointer;display:inline-block;overflow:hidden;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-tap-highlight-color:transparent;vertical-align:middle;z-index:1;/*will-change:opacity, transform;*/}
.waves-effect .waves-ripple{position:absolute;border-radius:50%;width:20px;height:20px;margin-top:-10px;margin-left:-10px;opacity:0;background:rgba(0, 0, 0, .1);transition:all 0.7s ease-out;-webkit-transition-property:-webkit-transform, opacity;-moz-transition-property:-moz-transform, opacity;-o-transition-property:-o-transform, opacity;transition-property:transform, opacity;-webkit-transform:scale(0);-moz-transform:scale(0);-ms-transform:scale(0);-o-transform:scale(0);transform:scale(0);pointer-events:none}
.waves-notransition{-webkit-transition:none !important;-moz-transition:none !important;-o-transition:none !important;-ms-transition:none !important;transition:none !important}
.waves-circle{-webkit-transform:translateZ(0);-moz-transform:translateZ(0);-ms-transform:translateZ(0);-o-transform:translateZ(0);transform:translateZ(0);-webkit-mask-image:-webkit-radial-gradient(circle, white 100%, black 100%)}
.waves-input-wrapper{border-radius:0.2em;vertical-align:bottom}
.waves-input-wrapper .waves-button-input{position:relative;top:0;left:0;z-index:1}
.waves-circle{text-align:center;width:2.5em;height:2.5em;line-height:2.5em;border-radius:50%;-webkit-mask-image:none}
.waves-block{display:block}

/* animation */
#pot{
  position: relative;
  animation: myfirst 0.7s 1;
  animation-direction: alternate;
}
#pot1{
  position: relative;
  animation: myfirst 0.5s 1;
  animation-direction: alternate;
  transition: transform .3s; 
}
#pot1:hover{
  transform: scale(1.2);
}
#pot2{
  position: relative;
  animation: myfirst 0.7s 1;
  animation-direction: alternate;
  transition: transform .3s; 
}
#pot2:hover{
  transform: scale(1.2);
}
#pot3{
  position: relative;
  animation: myfirst 2.5s 1;
  animation-direction: alternate;
  transition: transform .3s; 
}
#pot3:hover{
  transform: scale(1.4);
}

@keyframes myfirst {
  from {left: -300px;}
  to {left: 0px;}
}


#pot11{
  position: relative;
  animation: myfirst 2s 1;
  animation-direction: alternate;
}

/* asdasd */

#pot33{
  animation: sway 2.4s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}
#pot34{
  animation: sway 4s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}
#pot35{
  animation: sway 3s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}
#pot36{
  animation: sway 5s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}
@keyframes sway  {
  0% { transform: rotate(3deg); }
  50% { transform: rotate(-3deg); }
  100% { transform: rotate(3deg); }
}

#pot44{
  position: relative;
  animation: myfirst 1.1s 1;
  animation-direction: alternate;
}
#pot55{
  position: relative;
  animation: myfirst 1.2s 1;
  animation-direction: alternate;
}
#pot66{
  position: relative;
  animation: myfirst 1.3s 1;
  animation-direction: alternate;
}
/* google button */

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
}

.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  border: 10px solid #FBAA13;
  animation: loader 2s infinite ease;
}

.loader_text{
  display: inline-block;
  width: 100%;
  top: 12px;
  font-size: 15px;
  color: 	#07381B;
  position: relative;

}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #FBAA13;
  animation: loader-inner 2s infinite ease-in;
}
.loader-inner2 {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #FBAA13;
  animation: loader-inner 2s infinite ease-in;
}
.loader2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  position: relative;
  border: 3px solid #FBAA13;
  animation: loader 2s infinite ease;
}

@keyframes loader {
  0% { transform: rotate(0deg);}
  25% { transform: rotate(180deg);}
  50% { transform: rotate(180deg);}
  75% { transform: rotate(360deg);}
  100% { transform: rotate(360deg);}
}
@keyframes loader-inner {
  0% { height: 0%;}
  25% { height: 0%;}
  50% { height: 100%;}
  75% { height: 100%;}
  100% { height: 0%;}
}
.icons{
  padding:0px;
  position: relative;

}

.kakao-icon{
  width:24px;
  height: 24px;
  position: relative;
}
.notification{
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  background-color: red;
  border: solid 1px;
  
  border-radius: 50%;
  font-size: 11px;
}
.notification2{
  position: absolute;
  right: 19px;
  top:263px;
  width: 15px;
  height: 15px;
  background-color: red;
  border: solid 2px;
  border-radius: 100%;
  font-size: 11px;
}
.img-commerical{
  width: fit-content;
}
.messageBox{
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: Pretendard-Light;
  font-size: 15px;
  color:black;
  border: solid 2px;
  margin: 0 20px;
}
.messageBox .title{
  border-bottom: solid 2px;
  padding: 10px;
}
.messageBox .datetime{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: solid 2px;
}
.messageBox .date{
  border-right: solid 2px;
  padding: 10px;
}
.messageBox .time{

  padding: 10px;
}
.messageBox .info{
  padding: 10px;
  font-size: 15px;
}

.img-announce{
  width: 100%;
}
.img-announce2{
  width: 100%;
  transform: scale(1.5);
}
.img-announce2:hover{
  width: 100%;
  animation: shake 0.5s 1;
  animation-iteration-count: infinite;
  transform: scale(1.5);
}
.img-item{
  width: 90%;
  transition: transform .3s; 
}
.img-item:hover{
  transform: scale(1.1);
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg) scale(1.6); }
  10% { transform: translate(-1px, -2px) rotate(-1deg) scale(1.6); }
  20% { transform: translate(-3px, 0px) rotate(1deg) scale(1.6); }
  30% { transform: translate(3px, 2px) rotate(0deg) scale(1.6); }
  40% { transform: translate(1px, -1px) rotate(1deg) scale(1.6); }
  60% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.6); }
  60% { transform: translate(-3px, 1px) rotate(0deg) scale(1.6); }
  70% { transform: translate(3px, 1px) rotate(-1deg) scale(1.6); }
  80% { transform: translate(-1px, -1px) rotate(1deg) scale(1.6); }
  90% { transform: translate(1px, 2px) rotate(0deg) scale(1.6); }
  100% { transform: translate(1px, -2px) rotate(-1deg) scale(1.6); }
}


.thumbs-wrapper{
  width: 0;
  padding:0;
  height: 0;
}





.progress {
  position: relative;
  display: flex;
overflow-x: hidden;
padding: 20px 0 0 0;
}
.progress .progress-track {
  position: absolute;
  top: 5px;
  width: 100%;
  height: 5px;
  background-color:#dfe3e4;
  z-index: -1;
}
.progress .progress-step {
  position: relative;
  width: 100%;
  font-size: 12px;
  text-align: center;
/* 
  // Hide the final step's progress bar */
}
.progress .progress-step:before {
  content: '';
  display: flex;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 4px solid #dfe3e4;
  border-radius: 100%;
  color: #fff;
  justify-content: center;
  
}

.progress .progress-step:after {
  content:"";
  position: absolute;
  top: 6px;
  left: 50%;
  width: 0%;
  transition: width 1s ease-in;
  height: 5px;
  background: #dfe3e4;
  z-index: -1;
}

.progress .progress-step.is-active {
  color:  #2183dd
}

.progress .progress-step.is-active:before{
  border: 4px solid #dfe3e4;
  animation: pulse 2s infinite;
}

.progress .progress-step.is-complete {
  color: #009900;

}
.progress .progress-step.is-complete:before  {

  font-size: 10px;
  color: white;

  border: 4px solid  #009900;
}
.progress .progress-step.is-complete:after  {
  background: #FBAA13;
  animation: nextStep 1s;
  animation-fill-mode: forwards;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(33,131,221, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(33,131,221, 0); }
  100% { box-shadow: 0 0 0 0 rgba(33,131,221, 0); }
}
@keyframes nextStep {
  0% { width: 0%; }
  100% { width: 100%; }
}

.css-1u2w381-MuiModal-root-MuiDrawer-root{
  overflow-x: hidden;
}

@keyframes swing {
  0% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
}
.carousel .thumbs-wrapper{
  width: 0;
  padding:0;
  height: 0;
  margin: 0;
}
.carousel .control-arrow{
  height: 0;
}
.carousel{

  margin: 0;
}
.control-dots{

}


.bottomNav{
  position: fixed;
  bottom: 0;
  width: 100%;
  top: auto;
  color:#989898;
  border-top: solid 1px #F5F5F5;
  z-index: 100;
}

.bommNav .blocks{

  font-weight: 100;
  font-size: 10px;

}
.bottomNav .blocks .block {

  font-size: 10px;
}
.bottomNav .blocks .block2 {
  color:#FBAA13;
}

.Mui-selected{
  color:#4caf50;
  padding-top: 0;
  border-bottom: 1px solid;
}
.css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected{
  color:#4caf50;
}

@font-face {
  font-family: 'Pretendard-Light';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TmoneyRoundWindExtraBold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

